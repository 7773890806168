import { TimeRangePicker, TimeRangePickerProps } from "@wojtekmaj/react-timerange-picker";
import React, { useEffect, useRef } from "react";
import './TimeRangePicker.css';

const TimeRangePickerWrapper: React.FC<TimeRangePickerProps> = (props) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const wrapper = wrapperRef.current;
        if (!wrapper) return;

        const inputs = wrapper.querySelectorAll<HTMLInputElement>(
            ".react-timerange-picker__inputGroup__input"
        );

        const handlers: { input: HTMLInputElement; handler: () => void }[] = [];

        inputs.forEach((input) => {
            const handler = () => setTimeout(() => input.select(), 150);
            input.addEventListener("focus", handler);
            handlers.push({ input, handler });
        });

        return () => {
            handlers.forEach(({ input, handler }) => {
                input.removeEventListener("focus", handler);
            });
        };
    }, []);

    return (
        <div ref={wrapperRef}>
            <TimeRangePicker {...props} />
        </div>
    );
};

export default TimeRangePickerWrapper;
